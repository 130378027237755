<template>
  <div class="content">
    <div class="column">
      <h2>Sitemap</h2>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/team">Team</router-link></li>
        <li><router-link to="/connect">Connect</router-link></li>
        <!-- <li><router-link to="/login">Login</router-link></li> -->
      </ul>
    </div>
    <div class="column">
      <h2>Terms of Use</h2>
      <ul>
        <li><router-link to="/privacyPolicy">Privacy Policy</router-link></li>
        <li><router-link to="/termsOfService">Terms of service</router-link></li>
      </ul>
    </div>
    <!-- <div class="column"> -->
      <!-- <h2>Contact</h2> -->
      <!-- <ul> -->
        <!-- <li class="contact"><font-awesome-icon icon="envelope"></font-awesome-icon>ellomate@gmail.com</li>xs -->
      <!-- </ul> -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "FooterItem",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  // border-top: solid 0.1px var(--text-colour);
  box-shadow: 0 0 10px 0 var(--banner-colour);
  background: var(--alt-fade);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: top;
  padding: 2rem;
  gap: 2rem;

  .column {
    // height: 12rem;
    border-left: solid 2px var(--alt-text-colour);
    padding: 1rem;
    text-align: left;
    text-wrap: nowrap;
    h2 {
      font-size: 1.5rem;
      margin: 0;
      color: var(--alt-text-colour);
    }
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0.3rem 0rem;
        font-size: 1rem;
        a {
          text-decoration: none;
          color: var(--alt-text-colour);
          svg {
            .cls-1 {
              fill: var(--alt-text-colour);
            }
          }
        }
      }

      .contact {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1rem;
        color: var(--alt-text-colour);
      }
    }
    &:first-child {
      width: 100px;
      height: 100%;
    }
    &:last-child {
      justify-self: flex-start;
    }
  }
}

@media only screen and (orientation: portrait) {
  .parent {
    display: flex;
    flex-direction: column;
    // width: 100vw;
    .content {
      flex-direction: column;
      // width: 100vw;
      gap: 1rem;
      .column {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        h2 {
          font-size: 1.5rem;
          margin: 0;
        }
        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: left;
          li {
            list-style: none;
            margin: 0.5rem 0rem;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
