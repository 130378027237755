<template>
  <NavBar />

    <router-view />

  <FooterItem />
</template>

<script>
import NavBar from "./components/Nav.vue";
import FooterItem from "./components/Footer.vue";

export default {
  components: {
    NavBar,
    FooterItem,
  },
};
</script>

<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  background: var(--background-fade);
}
h1 {
  font-size: 2.5rem;
  color: var(--text-colour);
  margin: 0;
}
h2 {
  font-size: 2rem;
  color: var(--alt-text-colour);
  margin: 0;
}
h3 {
  font-size: 2rem;
  color: var(--text-colour);
}
h4 {
  font-size: 1.5rem;
  color: var(--action-colour);
  margin: 0.5rem;
}
h5 {
  font-size: 1.25rem;
  color: var(--text-colour);
  margin: 0;
}
p {
  font-size: 1rem;
  color: var(--alt-text-colour);
  margin: 0;
  font-weight: bold;
}
</style>
