<template>
  <div class="content">
    <h1>Connect Comfort to Culinary Bliss</h1>
    <section class="standout">
      <div class="standout-img">
        <img src="../../public/sales-growth.svg" alt="" />
      </div>
      <div class="inner-standout">
        <h4>Trade smarter with zero commissions.</h4>
        <h4>Keep more of your money while staying informed with free reporting.</h4>
        <h4>Experience growth with our low-stress, convenient platform.</h4>
        <h4>Start your journey today.</h4>
      </div>
    </section>
    <h3>Say goodbye to stress and hello to growth potential.</h3>
    <div class="hero">
      <h3>Don't believe us...</h3>
      <section class="testimonials">
        <div class="quote-1">
          <q>
            I have to commend our POS provider for their exceptional service. Their system has streamlined our business operations, and their customer support team is always responsive and helpful.
          </q>
        </div>
        <div class="quote-2">
          <q>
            I've been really impressed with our POS provider's innovative solutions. Their user-friendly interface and regular updates make managing our business a breeze. It's clear they're committed
            to helping us succeed.
          </q>
        </div>
      </section>
    </div>

    <h3>Why Choose Beamy</h3>
    <div class="content-body">
      <div class="content-box">
        <div class="content-box-overlay">
          <div class="content-box-text">
            <h2>Point of Sale</h2>
            <p>
              With a clean and user-friendly POS system, you can focus more on delivering excellent service to your customers, as it helps you manage sales, inventory, and the checkout process
              seamlessly.
            </p>
          </div>
        </div>
      </div>

      <div class="content-box">
        <div class="content-box-overlay">
          <div class="content-box-text">
            <h2>Kitchen Display</h2>
            <p>
              Our modern KDS keeps your kitchen operations up to date by displaying orders in real-time. This means your kitchen staff can stay organized and efficient, ensuring orders are prepared
              promptly and accurately.
            </p>
          </div>
        </div>
      </div>

      <div class="content-box">
        <div class="content-box-overlay">
          <div class="content-box-text">
            <h2>Reporting</h2>
            <p>
              With our reporting system, you can track your business's performance in real-time, identify trends, and spot opportunities for growth. Whether it's tracking sales, or analyzing customer
              behavior, these systems can provide a wealth of information.
            </p>
          </div>
        </div>
      </div>
      <div class="content-box">
        <div class="content-box-overlay">
          <div class="content-box-text">
            <h2>Online Ordering</h2>
            <p>
              Give your regular customers the convenience of ordering on their terms. Our user-friendly and efficient order sites provide a seamless ordering experience for you customer. Boost sales
              and improve customer satisfaction.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <router-link to="/connect"><button class="action-btn main-btn">Connect With Us</button></router-link>
      <router-link to="/team"><button class="action-btn secondary-btn">Meet the Team</button></router-link>
    </div>
    <div class="img-attribution">
      <a href="https://www.freepik.com/free-vector/team-goals-concept-illustration_12704339.htm#fromView=search&page=2&position=3&uuid=4b62de18-0d71-4ee8-8ff6-b86fb8f46697"
        >Illustration by storyset on Freepik</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang="scss">
.content {
  padding: 5rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .buttons {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 100%;
    .action-btn {
      border-radius: 15px;
      color: var(--alt-text-colour);
      background: var(--alt-fade);
      border: none;
      padding: 1rem 2rem;
      margin: 1rem;
      font-size: 1.5rem;
      cursor: pointer;
      width: 20rem;
      transition: all 500ms ease-in-out;
      &:hover {
        box-shadow: 0 0 10px 0 var(--primary-colour);
        border: 0.5px solid var(--tertiary-colour);
      }
    }
  }
  .hero {
    padding: 2rem;
    margin: 0;
    .testimonials {
      display: flex;
      flex-direction: row;
      div {
        padding: 2rem;
        text-align: center;

        q {
          font-size: x-large;
          color: var(--text-colour);
        }
      }
    }
    h1 {
      font-size: 4rem;
    }
  }

  .standout {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .inner-standout {
      width: 50%;
      text-align: left;
      h4 {
        margin: 1rem;
      }
    }
    .standout-img {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      img {
        width: 40rem;
      }
    }
  }

  h2 {
    font-size: 2rem;
  }

  .content-body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    flex-basis: auto;
    flex-wrap: wrap;
    margin: 0.5rem;
    .content-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20rem;
      width: 20rem;
      border-radius: 10px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 75%;
      .content-box-overlay {
        background: var(--alt-fade);
        height: 100%;
        border-radius: 10px;
        .content-box-text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          padding: 1rem;
          p,
          h2 {
            margin: 1rem 1rem;
          }
        }
      }
      &:nth-child(1) {
        background-image: url("../../public/pos-display.png");
      }
      &:nth-child(2) {
        background-image: url("../../public/kds-display.png");
      }
      &:nth-child(3) {
        background-image: url("../../public/reports-display.png");
      }
      &:nth-child(4) {
        background-image: url("../../public/test-ordering.jpeg");
      }
    }
  }
  .img-attribution {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    a {
      margin: 0;
      text-decoration: none;
      color: var(--background-colour);
      font-size: xx-small;
    }
  }
}

@media only screen and (orientation: portrait) {
  .content {
    padding: 7rem 1rem 0 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      text-align: center;
    }
    .hero {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h2 {
        text-align: center;
        text-wrap: nowrap;
      }
      .testimonials {
        div {
          q {
            font-size: medium;
          }
        }
        .quote-2 {
          display: none;
        }
      }
    }
    .standout {
      display: flex;
      flex-direction: column;
      .standout-img {
        img {
          width: 22rem;
        }
      }
      .inner-standout {
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }
    }
    h2 {
      width: 80%;
    }

    .buttons {
      padding-top: top;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .content-body {
      flex-direction: column;
      gap: 1rem;
      padding-top: 0rem;
      .content-box {
        width: 90%;
        height: 100%;
        .content-box-overlay {
          height: 100%;
          .content-box-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
