import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../components/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/connect",
    name: "connectItem",
    component: () => import("../components/Connect.vue"),
  },
  {
    path: "/team",
    name: "teamItem",
    component: () => import("../components/Team.vue"),
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicyItem",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/termsOfService",
    name: "termsOfServiceItem",
    component: () => import("../views/TermsOfService.vue"),
  },
  
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

export default router;
